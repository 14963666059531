<template>
  <div class="scroll">
    <app-search-rows-provider
      wrapper-class="search-component__wrapper-equal_fixed"
      search-wrapper-class="search-items__wrapper-equal"
      input-class="mb-6 pt-0 px-3"
      :items="defaultTableHeadersToPreview">
      <template #items="{ searchItems }">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              class="pt-0 px-0">
              <ProductCellCollectionsFormListEqual
                :headers="searchItems"
                :products="products"
                is-product-preview
                view="productCreate" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-search-rows-provider>
  </div>
</template>
<script>
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import ProductCellCollectionsFormListEqual
  from '@/components/Product/ProductCell/ProductCellCollectionsFormListEqual';
export default {
  name: 'ProductCellFieldProductSearch',
  components: {
    ProductCellCollectionsFormListEqual,
    AppSearchRowsProvider,
  },
  props: {
    products: {
      type: Array,
      default: () => ({
      }),
    },
    defaultTableHeadersToPreview: {
      type: Array,
      default: () => ({
      }),
    },
  },
};
</script>
